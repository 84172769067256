<template>
  <v-container>
    <new-account-category
      v-if="showNewModal"
      :parent-id="parent_id"
    />
    <edit-account-category v-if="showEditModal" />
    <new-accounting-account
      v-if="showNewAccountModal"
      :category-id="parent_id"
    />
    <edit-accounting-account v-if="showEditAccountModal" />
    <new-move
      v-if="showNewMoveModal"
      :account-parent="parent_id"
    />
    <v-card>
      <v-card-title>
        <v-list-item two-line>
          <v-list-item>
            <v-list-item-title>
              {{ $vuetify.lang.t('$vuetify.menu.accounting_category_list') }}
            </v-list-item-title>
            <v-list-item-icon v-if="access_permit.account_classify">
              <v-btn v-if="access_permit.account_classify.actions.create"
                class="mb-2"
                color="primary"
                @click="showNewAccount"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.actions.new') }}
              </v-btn>
            </v-list-item-icon>
          </v-list-item>
        </v-list-item>
      </v-card-title>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <category-accounting
            :account="tree"
            @addSubCategory="addSubCategory"
            @editCategoryHandler="editCategoryHandler"
            @deleteCategoryHandler="deleteCategoryHandler"
            @addAccountingAccount="addAccountingAccount"
            @editAccountHandler="editAccountHandler"
            @deleteAccountHandler="deleteAccountHandler"
            @addAccountingMove="addAccountingMove"
            @listMoveByCategory="listMoveByCategory"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CategoryAccounting from '../../accounting-category/commerce/CategoryAccounting'
import NewAccountCategory from './NewAccountCategory'
import EditAccountCategory from './EditAccountCategory'
import NewMove from '../../accounting-move/commerce/NewMove'
import NewAccountingAccount from '../../accounting-account/commerce/NewAccountingAccount'
import EditAccountingAccount from '../../accounting-account/commerce/EditAccountingAccount'

export default {
  name: 'ListAccountCategory',
  components: { EditAccountingAccount, NewMove, NewAccountingAccount, EditAccountCategory, NewAccountCategory, CategoryAccounting },
  data () {
    return {
      parent_id: ''
    }
  },
  computed: {
    ...mapState('accountCategory', [
      'showNewModal',
      'showEditModal',
      'showShowModal',
      'categories',
      'tree',
      'isTableLoading'
    ]),
    ...mapState('account', [
      'showNewAccountModal',
      'showEditAccountModal',
      'isTableLoading'
    ]),
    ...mapGetters('auth', ['access_permit']),
    ...mapState('accountMove', [
      'showNewMoveModal'
    ]),
    ...mapState('auth', ['cantBox', 'company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.color'),
          value: 'color'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  async created () {
    await this.getTree()
  },
  methods: {
    ...mapActions('accountCategory', [
      'toogleNewModal',
      'openEditModal',
      'openShowModal',
      'getTree',
      'deleteCategory'
    ]),
    ...mapActions('account', [
      'toogleNewAccountsModal',
      'openEditAccountsModal',
      'deleteAccount'
    ]),
    ...mapActions('accountMove', [
      'toogleNewMovesModal'
    ]),
    showNewAccount () {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.toogleNewModal(true)
        this.parent_id = ''
      }
    },
    showSubscriptionMessage () {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.actions.subscribe'),
          text: this.$vuetify.lang.t('$vuetify.actions.is_caduced'),
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'my_subscription' })
          }
        })
    },
    showArticlesByCategory (accountId) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.$router.push({ name: 'product_by_account', params: { accountId } }) }
    },
    addAccountingAccount (category) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.parent_id = category
        this.toogleNewAccountsModal(true)
      }
    },
    addAccountingMove (parent) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.parent_id = parent
        this.toogleNewMovesModal(true)
      }
    },
    listMoveByCategory (parent) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.$router.push({ name: 'account_move_by_category', params: { account: parent } }) }
    },
    addSubCategory (parent) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        this.parent_id = parent.id
        this.toogleNewModal(true)
      }
    },
    editCategoryHandler (item) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.openEditModal(item) }
    },
    editAccountHandler ($event) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else { this.openEditAccountsModal($event) }
    },
    deleteCategoryHandler (item) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        item.default_category
          ? this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.account_classify')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.no_delete_default'
              ),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'primary'
            })
          : this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.account_classify')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_delete'
              ),
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.delete'
              ),
              confirmButtonColor: 'red'
            })
            .then(result => {
              if (result.value) this.deleteCategory(item.id)
            })
      }
    },
    deleteAccountHandler (item) {
      if (!this.getSubscription) {
        this.showSubscriptionMessage()
      } else {
        item.default
          ? this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.accounting')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.no_delete_default'
              ),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.accept'
              ),
              confirmButtonColor: 'primary'
            })
          : this.$Swal
            .fire({
              title: this.$vuetify.lang.t('$vuetify.titles.delete', [
                this.$vuetify.lang.t('$vuetify.menu.accounting')
              ]),
              text: this.$vuetify.lang.t(
                '$vuetify.messages.warning_delete'
              ),
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.cancel'
              ),
              confirmButtonText: this.$vuetify.lang.t(
                '$vuetify.actions.delete'
              ),
              confirmButtonColor: 'red'
            })
            .then(result => {
              if (result.value) this.deleteAccount(item.id)
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
