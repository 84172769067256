<template>
  <v-card>
    <v-card-text>
      <v-treeview
        :active.sync="active"
        :items="accounts"
        :open.sync="open"
        activatable
        open-on-click
        transition
      >
        <template v-slot:label="{ item }">
          <v-list-item two-line>
              <!--<v-tooltip max-width="350" right class="md-6">-->
              <v-tooltip
              v-if="item.id === '' || !item.category_id && (item.default_category || item.description)"
                top
                max-width="350" class="md-6"
              >
                <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                        >
                          <span
                            v-if="item.id"
                            class="subtitle-2 font-weight-light"
                          >
                            {{ item.category_id? item.code +'- ' +  item.default ? getNameCategory(item) : item.name : item.default_category ?
                              $vuetify.lang.t('$vuetify.accounting_category.' + item.name) + '(' + parseFloat(item.current_balance).toFixed(2) + ' ' + getCurrency + ')' :
                            (item.default ? getNameCategory(item) : item.name) + '(' + parseFloat(item.current_balance).toFixed(2) + ' ' + getCurrency + ')'}}</span>
                          <span v-else> {{item.default ? getNameCategory(item) : item.name}}{{' (' + $vuetify.lang.t('$vuetify.boxes.final') + ': ' + parseFloat(totalBalance).toFixed(2)+ ' ' + getCurrency + ')'}}</span>
                        </v-list-item-title>
                </template>
                <span>
                  {{ item.id === '' ? $vuetify.lang.t('$vuetify.hints.account_archive') : item.default_category ? $vuetify.lang.t('$vuetify.hints.account_' + item.name) : item.description }}
                </span>
              </v-tooltip>
              <!---->
              <v-list-item-title v-else>
                <span>{{item.default ? getNameCategory(item) : item.name}} {{ ' (' + parseFloat(item.current_balance).toFixed(2) + ' ' + getCurrency + ')'}}</span>
              </v-list-item-title>
            <v-list-item-icon v-if="item.id !== '' && access_permit.account_classify && access_permit.accounting_account">
              <v-spacer />
              <v-tooltip v-if="!item.category_id && access_permit.account_classify.actions.create"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('addSubCategory', item)"
                  >
                    mdi-folder-plus
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.created', [$vuetify.lang.t('$vuetify.menu.sub_file')]) }}</span>
              </v-tooltip>
              <v-tooltip v-if="!item.category_id"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('addAccountingAccount', item.id)"
                  >
                    mdi-bookmark-plus-outline
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.created', [$vuetify.lang.t('$vuetify.menu.accounting')]) }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.category_id && access_permit.accounting_account.actions.create"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('addAccountingMove', item)"
                  >
                    mdi-tag-plus
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.created', [$vuetify.lang.t('$vuetify.menu.account_move')]) }}</span>
              </v-tooltip>
              <v-tooltip v-if="item.category_id && isManagerIn"
                top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    small
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('listMoveByCategory', item.id)"
                  >
                    mdi-tag-multiple
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.show', [$vuetify.lang.t('$vuetify.menu.account_moves')]) }}</span>
              </v-tooltip>
              <v-tooltip top v-if="(!item.category_id && access_permit.account_classify.actions.edit) || (item.category_id && access_permit.accounting_account.actions.edit)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    small
                    color="warning"
                    v-bind="attrs"
                    v-on="on"

                    @click="!item.category_id ? $emit('editCategoryHandler', item):$emit('editAccountHandler', item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.edit', item.category_id?[$vuetify.lang.t('$vuetify.menu.account_move')]:[$vuetify.lang.t('$vuetify.menu.account_classify')]) }}</span>
              </v-tooltip>
              <v-tooltip top v-if="(!item.category_id && access_permit.account_classify.actions.delete) || (item.category_id && access_permit.accounting_account.actions.delete)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="error"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="!item.category_id ? $emit('deleteCategoryHandler', item):$emit('deleteAccountHandler', item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.actions.delete', item.category_id?[$vuetify.lang.t('$vuetify.menu.account_move')]:[$vuetify.lang.t('$vuetify.menu.account_classify')]) }}</span>
              </v-tooltip>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.file">
            {{ open ? 'mdi-folder-open' : item.category_id ?
              'mdi-bookmark-outline':'mdi-folder' }}
          </v-icon>
          <v-icon
            v-else
          >
            {{ files[item.file] }}
          </v-icon>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'

const pause = ms => new Promise(resolve => setTimeout(resolve, ms))

export default {
  name: 'CategoryAccounting',
  props: {
    account: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data: () => ({
    active: [],
    avatar: null,
    open: [1]
  }),
  computed: {
    ...mapGetters('auth', ['user', 'access_permit', 'isManagerIn']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    totalBalance () {
      let cant = 0
      this.account.forEach((v) => {
        if (v.nature === 'creditor') { cant = cant + v.current_balance } else { cant = cant + v.current_balance }
      })
      return cant
    },
    accounts () {
      const result = {
        name: this.$vuetify.lang.t('$vuetify.menu.account_classify'),
        children: this.account,
        current_balance: this.account.reduce((a, b) => a.current_balance + b.current_balance, 0),
        id: ''
      }
      return [
        result
      ]
    },
    selected () {
      if (!this.active.length) return undefined
      const id = this.active[0]
      return this.account.accounts.find(act => act.id === id)
    }
  },
  methods: {
    getNameCategory (item) {
      const splitName = item.name.split('-')
      return splitName[0] + '-' + this.$vuetify.lang.t('$vuetify.accounting_category.' + splitName[1])
      // return item
    }
  }
}
</script>

<style scoped>

</style>
